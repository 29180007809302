import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, gql, useQuery } from "@apollo/client";
import { notification, PageHeader, Button, Form } from "antd";

import { PageContainer } from "../components/atoms/PageContainer";
import { CustomerForm } from "../components/CustomerForm";
// import for achiving the function of UnsavedReminder
import UnsavedReminder from "../components/atoms/UnsavedReminder";
import { useCallbackPrompt } from "../hooks/useCallbackPrompt";
import { prepareCustomerFormValues } from "../utils";

const UpdateCustomerMutation = gql`
  mutation CreateCustomerMutation($id: ID!, $data: CustomerUpdateInput!) {
    updateCustomer(where: { id: $id }, data: $data) {
      id
      name
      phone
      notes
      abn
      businessName
      fixedMargin
      LEGACY_customerCode
    }
  }
`;

const SingleCustomerBaiscDataQuery = gql`
  query SingleCustomerBaiscDataQuery($id: ID!) {
    customer(where: { id: $id }) {
      id
      name
      deliveryAddress
      abn
      businessName
      notes
      phone
      email
      invoiceTemplate
      fixedMargin
      LEGACY_customerCode
      franchise {
        id
        name
      }
      salesperson {
        id
        name
      }
    }
  }
`;

export const EditCustomer = () => {
  const params = useParams();
  const [updateCustomer] = useMutation(UpdateCustomerMutation);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  // unsavedReminder
  const [showModal, setShowModal] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(true);
  const [isSaved, setIsSaved] = useState(false);

  const { data, loading } = useQuery(SingleCustomerBaiscDataQuery, {
    variables: { id: params.id },
  });

  useEffect(() => {
    if (data?.customer) {
      const { franchise, salesperson } = data.customer;
      const customerFormValues = {
        ...data.customer,
        franchise: franchise?.id,
        salesperson: salesperson?.id,
        franchiseName: franchise?.name,
        franchiseId: franchise?.id,
      };
      form.setFieldsValue(customerFormValues);
    }
  }, [data, loading, form]);

  useEffect(() => {
    if (isSaved) {
      setShowModal(false);
    } else setShowModal(showPrompt);
  }, [showPrompt, isSaved]);

  const handleSave = async () => {
    setIsSaved(true);
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      const preparedValues = prepareCustomerFormValues(values);

      console.log("preparedValues: ", preparedValues);
      const res = await updateCustomer({
        variables: { id: params.id, data: preparedValues },
      });
      if (res.data.updateCustomer.id) {
        notification.success({
          message: `${res.data.updateCustomer.name} successfully updated.`,
        });
        navigate("/customers");
        confirmNavigation();
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <PageContainer>
      <UnsavedReminder
        showModal={showModal}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <PageHeader
        ghost={true}
        title="New customer"
        onBack={() => navigate(-1)}
        extra={[
          <Button type="primary" key="save" onClick={handleSave}>
            Save
          </Button>,
        ]}
      />

      <CustomerForm form={form} />
    </PageContainer>
  );
};
