import { useQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  SinglePaymentBatchQuery,
  DeletePaymentBatchMutation,
  PaymentBatchesQuery,
  UpdatePaymentBatchMutaion,
} from "../graphql/paymentBatches.graphql";
import moment from "moment";
import { formatMoney } from "../utils";
import { PageContainer } from "../components/atoms/PageContainer";
import {
  PageHeader,
  Table,
  Card,
  Button,
  Form,
  Input,
  DatePicker,
  Space,
  notification,
  Tag,
} from "antd";
import { DeleteWithModalConfirm } from "../components/DeleteWithModalConfirm";
import { Link } from "react-router-dom";
import { StatusTag } from "../components/tags/StatusTag";
import _ from "lodash";
import { OrdersQuery, UpdateOrdersMutation } from "../graphql/orders.graphql";
import axios from "axios";
const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },

  {
    title: "Order/Invoice",
    dataIndex: "order",
    key: "order",
    render: (_, record) => {
      return record.order ? (
        <Space>
          <Link to={`/orders/${record.order?.id}`}># {record.order.name}</Link>
          <StatusTag status={record.order.paymentStatus} />
        </Space>
      ) : (
        <Tag color="red">No order found, please check</Tag>
      );
    },
  },

  {
    title: "Notes",
    dataIndex: "notes",
    key: "notes",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    align: "right",
    render: formatMoney,
  },
];

export const PaymentDetail = () => {
  const params = useParams();
  const { data } = useQuery(SinglePaymentBatchQuery, {
    variables: { id: params.id },
  });
  const [dateString, setDateString] = useState("");
  const [paymentBatch, setPaymentBatch] = useState({ payments: [] });
  const [form] = Form.useForm();
  const [deletePaymentBatch] = useMutation(DeletePaymentBatchMutation, {
    refetchQueries: [{ query: PaymentBatchesQuery }],
  });

  const [updatePaymentBatch] = useMutation(UpdatePaymentBatchMutaion, {
    refetchQueries: [{ query: PaymentBatchesQuery }],
  });

  const [updateOrders] = useMutation(UpdateOrdersMutation, {
    refetchQueries: [{ query: OrdersQuery }],
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.paymentBatch) {
      setPaymentBatch(data.paymentBatch);
      setDateString(moment(data.paymentBatch.receivedDate).format("DD/MM/YYYY"));
      form.setFieldsValue({
        receivedDate: moment(data.paymentBatch.receivedDate),
        notes: data.paymentBatch.notes,
      });
    }
  }, [data, form]);

  // const handleDeletePaymentBatch = async () => {
  //   // delete the payment batch
  //   const ordersMapped = paymentBatch.payments.map((payment) => ({
  //     ...payment.order,
  //     amount: payment.amount,
  //   }));
  //   const ordersGrouped = _.groupBy(ordersMapped, "id");

  //   const ordersUpdateData = Object.keys(ordersGrouped).map((key) => {
  //     const balancePaid = Number(ordersGrouped[key][0].balancePaid);
  //     const total = Number(ordersGrouped[key][0].total);
  //     const amountTotal = ordersGrouped[key].reduce((total, current) => {
  //       return total + Number(current.amount);
  //     }, 0);
  //     // balance paid deduct the deelted payments total amount
  //     const newPaid = balancePaid - amountTotal;
  //     const due = total - newPaid;
  //     let status = "unpaid";
  //     if (due === 0) {
  //       status = "paid";
  //     }
  //     if (due === total) {
  //       status = "unpaid";
  //     } else if (due > 0) {
  //       status = "partially paid";
  //     }

  //     return {
  //       where: { id: key },
  //       data: {
  //         balancePaid: String(newPaid),
  //         balanceDue: String(due),
  //         paymentStatus: status,
  //         payments: { set: [] },
  //       },
  //     };
  //   });

  //   const res = await deletePaymentBatch({ variables: { id: params.id } });
  //   if (res.data) {
  //     notification.success({
  //       message: "Payments deleted",
  //       placement: "bottomRight",
  //     });
  //     const orderUpdateRes = await updateOrders({
  //       variables: { data: ordersUpdateData },
  //     });
  //     if (orderUpdateRes.data) {
  //       notification.success({
  //         message: "Related orders updated",
  //         placement: "bottomRight",
  //       });
  //       navigate(-1);
  //     }
  //   }
  // };

  const handleDeletePaymentBatchViaRestEndpoint = async () => {
    const res = await axios.delete(
      `${process.env.REACT_APP_REST_API_ENDPOINT}/payments/${params.id}`
    );
    if (res.ok) {
      notification.success({ message: "Payment batch deleted." });
      navigate(-1);
    }
  };

  const handleSavePaymentBatch = async () => {
    const values = form.getFieldsValue();

    const res = await updatePaymentBatch({ variables: { id: params.id, data: values } });
    if (res.data) {
      notification.success({ message: "Payment batch updated." });
      navigate("/payments");
    }
  };

  return (
    <PageContainer>
      <PageHeader
        title={`Payment from ${paymentBatch?.customer?.name || ""}`}
        subTitle={dateString}
        extra={[
          <DeleteWithModalConfirm
            onConfirm={handleDeletePaymentBatchViaRestEndpoint}
            key="delete"
          />,
        ]}
      />
      <Card
        title="Payment info"
        style={{ width: "100%" }}
        extra={[
          <Button key="save" type="primary" onClick={handleSavePaymentBatch}>
            Save
          </Button>,
        ]}
      >
        <Form form={form}>
          <Form.Item name="receivedDate" label="Received at">
            <DatePicker />
          </Form.Item>
          <Form.Item name="notes" label="Notes">
            <Input />
          </Form.Item>
        </Form>
      </Card>
      <Card title="Breakdown" style={{ width: "100%" }}>
        <Table
          columns={columns}
          size="small"
          dataSource={paymentBatch?.payments}
          pagination={false}
        />
      </Card>
    </PageContainer>
  );
};
